import actionTypes from './actionTypes';
import axios from '../../axios-instance';

export const setPosts = posts => {
    return {
        type: actionTypes.UPDATE_POSTS_LIST,
        posts: posts
    };
};

export const setPostsLoading = postsLoading => {
    return {
        type: actionTypes.SET_POSTS_LOADING,
        postsLoading
    };
};

export const getPosts = (isAuth) => {
    return dispatch => {
        let url = '/posts.json';

        if (!isAuth) {
            url += '?orderBy="isDraft"&equalTo=false';
        }

        dispatch(setPostsLoading(true));
        axios.get(url).then(response => {
            console.log(response);
            const posts = [];
            for (let id in response.data) {
                posts.push({
                    id: id,
                    ...response.data[id]
                })
            }
            dispatch(setPosts(posts));
            dispatch(setPostsLoading(false));
        }).catch(error => {
            console.log(error)
            dispatch(setPostsLoading(false));
        })
    }
};

export const getPostsByTag = tag => {
    return {
        type: actionTypes.GET_POSTS_BY_TAG,
        tag
    };
}

export const addPost = post => {
    return dispatch => {
        const token = localStorage.getItem('token');
        axios.post(`/posts.json?auth=${token}`, post).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }
};

export const deletePost = postId => {
    return async (dispatch, getState) => {
        const { postList } = getState().posts;
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`/posts/${postId}.json?auth=${token}`);
            const newPosts = postList.filter(post => post.id !== postId);
            dispatch(setPosts(newPosts));
        } catch (error) {
            console.log(error)
        }
    }
};

export const setPostBody = body => {
    return {
        type: actionTypes.SET_SELECTED_POST_BODY,
        body: body
    };
};

export const setPostBodyLoading = postBodyLoading => {
    return {
        type: actionTypes.SET_POST_BODY_LOADING,
        postBodyLoading
    };
};

//Depricated
export const getPostBody = postId => {
    return dispatch => {
        dispatch(setPostBodyLoading(true))
        axios.get(`/posts/${postId}.json`).then(response => {
            console.log(response);
            dispatch(setPostBody(response.data.body))
            dispatch(setPostBodyLoading(false))
        }).catch(error => {
            console.log(error)
            dispatch(setPostBodyLoading(false))
        })
    }
};

export const getPostById = id => {
    return {
        type: actionTypes.GET_POST_BY_ID,
        id: id
    };
};

export const setPostById = post => {
    return {
        type: actionTypes.SET_POST_BY_ID,
        post
    };
};

export const getPostByIdAsync = id => {
    return dispatch => {
        dispatch(setPostBodyLoading(true))
        axios.get(`/posts/${id}.json`).then(response => {
            console.log(response);
            dispatch(setPostById(response.data));
            dispatch(setPostBody(response.data.body));
            dispatch(setPostBodyLoading(false))
        }).catch(error => {
            console.log(error)
            dispatch(setPostBodyLoading(false))
        })
    }
};

export const setSelectedPost = post => {
    return {
        type: actionTypes.SET_SELECTED_POST,
        post
    };
};

export const setPostTags = postTags => {
    return {
        type: actionTypes.SET_POST_TAGS,
        postTags: postTags
    };
};

export const getPostTags = () => {
    return dispatch => {
        axios.get('/postTags.json').then(response => {
            console.log(response);
            const postTags = [];
            for (let id in response.data) {
                postTags.push({ id: id, ...response.data[id] })
            }
            dispatch(setPostTags(postTags));
        }).catch(error => {
            console.log(error)
        })
    }
};

export const refreshPostTags = postTag => {
    return {
        type: actionTypes.REFRESH_POST_TAGS,
        postTag
    };
}

export const addPostTag = postTag => {
    return dispatch => {
        const token = localStorage.getItem('token');
        axios.post(`/postTags.json?auth=${token}`, { title: postTag }).then(response => {
            console.log(response);
            dispatch(refreshPostTags(postTag))
        }).catch(error => {
            console.log(error)
        })
    }
};

export const setSelectedTag = tagId => {
    return {
        type: actionTypes.SET_SELECTED_TAG,
        tagId: tagId
    };
};

export const getDrafts = tagId => {
    return {
        type: actionTypes.GET_DRAFTS,
        tagId: tagId
    };
};

export const updatePostTag = newTag => {
    return {
        type: actionTypes.UPDATE_POST_TAG,
        tagId: newTag.tagId,
        value: newTag.value
    };
};

export const editPostTag = (tagId, value) => {
    return async (dispatch, getState) => {
        const { postList, postTags } = getState().posts;
        const oldPostList = [...postList];
        const oldPostTags = [...postTags];
        const tagToUpdate = oldPostTags.find(tag => tag.id === tagId);
        const postsWithThisTag = oldPostList.filter(post => !!post.tags && post.tags.some(tag => tag === tagToUpdate.title));
        const token = localStorage.getItem('token');
        try {
            await axios.put(`/postTags/${tagId}.json?auth=${token}`, { title: value });
            if (postsWithThisTag.length > 0) {
                const newPostList = [];
                oldPostList.forEach(post => {
                    const postToBeChanged = postsWithThisTag.find(p => p.id === post.id);
                    if (postToBeChanged) {
                        const newPost = {
                            title: post.title,
                            abstruct: post.abstruct,
                            image: post.image,
                            tags: post.tags.map(tag => tag === tagToUpdate.title ? value : tag),
                            body: post.body
                        }
                        dispatch(editPost(post.id, newPost))
                        newPostList.push({ id: post.id, ...newPost });
                    } else {
                        newPostList.push(post);
                    }
                })
                dispatch(setPosts(newPostList));
            }
            dispatch(updatePostTag({ tagId: tagId, value: value }));
        } catch (error) {
            console.log(error)
        }
    }
};

export const deletePostTag = tagId => {
    return async (dispatch, getState) => {
        const { postList, postTags } = getState().posts;
        const oldPostList = [...postList];
        const tagToDelete = postTags.find(tag => tag.id === tagId);
        const postsWithThisTag = oldPostList.filter(post => post.tags && post.tags.some(tag => tag === tagToDelete.title));
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`/postTags/${tagId}.json?auth=${token}`);
            const newTags = postTags.filter(tag => tag.id !== tagId);
            if (postsWithThisTag.length > 0) {
                const newPostList = [];
                oldPostList.forEach(post => {
                    const postToBeChanged = postsWithThisTag.find(p => p.id === post.id);
                    if (postToBeChanged) {
                        const newPost = {
                            title: post.title,
                            abstruct: post.abstruct,
                            image: post.image,
                            tags: !!post.tags && post.tags.filter(tag => tag !== tagToDelete.title),
                            body: post.body
                        }
                        dispatch(editPost(post.id, newPost))
                        newPostList.push({ id: post.id, ...newPost });
                    } else {
                        newPostList.push(post);
                    }
                })
                dispatch(setPosts(newPostList));
            }
            dispatch(setPostTags(newTags));
        } catch (error) {
            console.log(error)
        }
    }
};

export const editPost = (postId, newPost) => {
    return dispatch => {
        const token = localStorage.getItem('token');
        axios.put(`/posts/${postId}.json?auth=${token}`, newPost).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }
};