import actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utils';

const initialState = {
    postList: [],
    selectedPostBody: null,
    postTags: [],
    selectedTag: '',
    selectedPost: {},
    postBodyLoading: false,
    postsLoading: false
};

const updatePostList = (state, action) => {
    return updateObject(state, {
        postList: action.posts
    });
};

const setSelectedPostBody = (state, action) => {
    return { ...state, selectedPostBody: action.body }
};

const getPostById = (state, action) => {
    return updateObject(state, {
        selectedPost: state.postList.find(post => post.id === action.id)
    });
};

const setSelectedPost = (state, action) => {
    return updateObject(state, {
        selectedPost: { ...action.post }
    });
};

const setPostTags = (state, action) => {
    console.log(action)
    return { ...state, postTags: [...action.postTags] }
};

const getPostsByTag = (state, action) => {
    return { ...state, postList: state.postList.filter(post => !!post.tags && post.tags.some(tag => tag === action.tag)) }
};

const setSelectedTag = (state, action) => {
    return updateObject(state, {
        selectedTag: action.tagId ? state.postTags.find(tag => tag.id === action.tagId) : { id: null, title: null }
    });
};

const getDrafts = (state, action) => {
    return { ...state, postList: state.postList.filter(post => post.isDraft) }

};

const setPostBodyLoading = (state, action) => {
    return { ...state, postBodyLoading: action.postBodyLoading }
}

const setPostsLoading = (state, action) => {
    return { ...state, postsLoading: action.postsLoading }
}

const updatePostTag = (state, action) => {
    const newTags = [...state.postTags];
    const tagIndex = newTags.findIndex(tag => tag.id === action.tagId);
    newTags[tagIndex].title = action.value;
    return { ...state, postTags: newTags }
};

const refreshPostTags = (state, action) => {
    console.log(action)
    return { ...state, postTags: [...state.postTags, { id: action.postTag, title: action.postTag }] }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_POSTS_LIST: return updatePostList(state, action);
        case actionTypes.SET_SELECTED_POST_BODY: return setSelectedPostBody(state, action);
        case actionTypes.SET_POST_TAGS: return setPostTags(state, action);
        case actionTypes.SET_SELECTED_TAG: return setSelectedTag(state, action);
        case actionTypes.UPDATE_POST_TAG: return updatePostTag(state, action);
        case actionTypes.GET_POST_BY_ID: return getPostById(state, action);
        case actionTypes.SET_POST_BODY_LOADING: return setPostBodyLoading(state, action);
        case actionTypes.GET_POSTS_BY_TAG: return getPostsByTag(state, action);
        case actionTypes.REFRESH_POST_TAGS: return refreshPostTags(state, action);
        case actionTypes.SET_POSTS_LOADING: return setPostsLoading(state, action);
        case actionTypes.SET_SELECTED_POST: return setSelectedPost(state, action);
        case actionTypes.SET_POST_BY_ID: return setSelectedPost(state, action);
        case actionTypes.GET_DRAFTS: return getDrafts(state, action);
        default: return state;
    }
};

export default reducer;