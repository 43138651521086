import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import resources from './resources/locales.json';

i18n.use(initReactI18next)
    .init({
        // we init with resources
        lang: 'el',
        resources: resources,
        fallbackLng: "el",
        debug: true,
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        }
    });

export default i18n;