import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PersonIcon from '@mui/icons-material/Person';
import logoIcon from '../../assets/images/logo.png';
import logoIconHorizontal from '../../assets/images/logo-horizontal.png';
import * as navActions from '../../store/actions/navigation';
import { useSelector, useDispatch } from 'react-redux';
import { tabs } from '../../common/constants';

import classes from './Toolbar.module.css'

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const hasInCart = false; //To change
  const isLoggedIn = false; //To change

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  const chosenTab = useSelector(state => state.nav.chosenTab);
  const isAuth = useSelector(state => !!state.auth.token);

  const shouldShowTab = tab => {
    return !tab.isAlpha || isAuth;
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpened(open);
  };

  let loginOrMenu = (
    <React.Fragment>
      <Tooltip title={t('res_AccountTooltip')}>
        <Button onClick={handleOpenUserMenu} color="inherit" sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}>
          <PersonIcon fontSize='large' color="secondary" />
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>
          <Typography textAlign="center">{t('res_SignIn')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleCloseUserMenu}>
          <Typography textAlign="center">{t('res_SignUp')}</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )

  if (isLoggedIn) {
    loginOrMenu = (
      <React.Fragment>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    )
  }

  let cartIcon = <ShoppingCartIcon fontSize='large' color="secondary" />;

  if (hasInCart) {
    cartIcon = <ShoppingCartCheckoutIcon fontSize='large' color="secondary" />;
  }

  return (
    <header className={classes.Toolbar}>
      <AppBar position="static" color="primary" sx={{ width: '100vw' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img className={classes.logo} alt="logo" src={logoIcon} onClick={() => {
                handleCloseNavMenu();
                navigate('/');
                dispatch(navActions.changeTab());
              }} />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {tabs.map((page) => {
                  if (shouldShowTab(page)) {
                    return (
                      <MenuItem key={page.title} onClick={() => {
                        handleCloseNavMenu();
                        navigate(page.link);
                      }}>
                        <Typography textAlign="center" sx={{ textTransform: 'none' }}>{t(page.title)}</Typography>
                      </MenuItem>
                    )
                  }
                  return null;
                })}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img className={classes.logoHorizontal} alt="logo" src={logoIconHorizontal} onClick={() => {
                handleCloseNavMenu();
                navigate('/');
                dispatch(navActions.changeTab())
              }} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {tabs.map((page) => {
                if (shouldShowTab(page)) {
                  return (
                    <Button
                      key={page.title}
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(page.link);
                        dispatch(navActions.changeTab(page.title));
                      }}
                      sx={{ my: 2, color: chosenTab === page.title ? '#57715d' : 'black', display: 'block', textTransform: 'none', fontSize: 14 }}
                    >
                      {t(page.title)}
                    </Button>
                  )
                }
                return null;
              })}
            </Box>

            {isAuth ? (<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
              {loginOrMenu}
              <Button
                onClick={toggleDrawer(true)}
                sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
              >
                {cartIcon}
              </Button>
            </Box>) : null}
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        Shopping cart goes here!
      </SwipeableDrawer>
    </header>
  );
};
export default ResponsiveAppBar;