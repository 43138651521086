import React from 'react';
import Toolbar from '../../Components/Toolbar/Toolbar';
import classes from './Layout.module.css';
import Footer from '../../Components/Footer/Footer';
import Divider from '@mui/material/Divider';

const Layout = props => {
    return (
        <div className={classes.pageWrapper}>
            <div className={classes.toolbarWrapper}>
                <Toolbar />
            </div>
            <main className={classes.layoutWrapper}>
                {props.children}
                <Divider sx={{ width: '100%', marginTop: 1.5 }} />
                <Footer />
            </main>
        </div>
    )
}

export default Layout;