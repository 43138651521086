import React from 'react';

const Appointments = props => {
    return (
        <div>
            Appointments works!
        </div>
    )
}

export default Appointments;