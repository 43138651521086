const actions = {
    AUTH_FAIL: 'AUTH_FAIL',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_START: 'AUTH_START',
    AUTH_SUCCESS: 'AUTH_SUCCESS',
    CHANGE_TAB: 'CHANGE_TAB',
    GET_POST_BY_ID: 'GET_POST_BY_ID',
    GET_POSTS_BY_TAG: 'GET_POSTS_BY_TAG',
    REFRESH_POST_TAGS: 'REFRESH_POST_TAGS',
    SET_AUTH_REDIRECT_PATH: 'SET_AUTH_REDIRECT_PATH',
    SET_SELECTED_POST_BODY: 'SET_SELECTED_POST_BODY',
    UPDATE_POSTS_LIST: 'UPDATE_POSTS_LIST',
    SET_POST_TAGS: 'SET_POST_TAGS',
    SET_SELECTED_TAG: 'SET_SELECTED_TAG',
    ADD_NEW_TAG: 'ADD_NEW_TAG',
    UPDATE_POST_TAG: 'UPDATE_POST_TAG',
    SET_POST_BODY_LOADING: 'SET_POST_BODY_LOADING',
    SET_POSTS_LOADING: 'SET_POSTS_LOADING',
    SET_SELECTED_POST: 'SET_SELECTED_POST',
    SET_POST_BY_ID: 'SET_POST_BY_ID',
    GET_DRAFTS: 'GET_DRAFTS'
};

export default actions;