export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const areImagesLoaded = async (images) => {
    const singleImageLoaded = name => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = require(`../assets/images/${name}`);
            image.onload = resolve();
            image.onerror = reject();
        });
    }

    if (!images) {
        return true;
    }

    const promises = images.map(img => singleImageLoaded(img));
    try {
        await Promise.all(promises);
        return true;
    } catch (error) {
        console.log(error);
        return true;
    }
}