import React from 'react';

const Eshop = props => {
    return (
        <div>
            Eshop works!
        </div>
    )
}

export default Eshop;