import actionTypes from './actionTypes';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const initializeFirebaseApp = () => {
    // Set the configuration for your app
    const firebaseConfig = {
        apiKey: 'AIzaSyATFr7CTo9q2i2DkKKPoMVPxrmxcTS3itY',
        authDomain: 'my-dietitian-mama-default-rtdb.europe-west1.firebasedatabase.app',
        databaseURL: 'https://my-dietitian-mama-default-rtdb.europe-west1.firebasedatabase.app/',
        storageBucket: 'my-dietitian-mama.appspot.com'
    };
    const firebaseApp = initializeApp(firebaseConfig);

    // Get a reference to the storage service, which is used to create references in your storage bucket
    const storage = getStorage(firebaseApp);

    return { firebaseApp, storage };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, firebaseApp, storage) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        firebaseApp,
        storage
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password, isSignup) => {
    return dispatch => {
        dispatch(authStart());
        const { firebaseApp, storage } = initializeFirebaseApp();
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const response = userCredential._tokenResponse;
                const expirationDate = new Date(new Date().getTime() + response.expiresIn * 1000);
                localStorage.setItem('token', response.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.localId);
                dispatch(authSuccess(response.idToken, response.localId, firebaseApp, storage));
                dispatch(checkAuthTimeout(response.expiresIn));
            })
            .catch((error) => {
                console.log(error)
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const { firebaseApp, storage } = initializeFirebaseApp();

                const auth = getAuth();
                //Check if this is needed??
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        console.log(user)
                    } else {
                        // User is signed out
                        // ...
                    }
                });
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId, firebaseApp, storage));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    };
};