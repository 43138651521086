import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classes from './PageHeader.module.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const PageHeader = props => {

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedTagId, setSelectedTagId] = useState('');

    const handleOpen = (tag) => {
        setInputValue(tag.title);
        setSelectedTagId(tag.id)
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const { t } = useTranslation();
    const isAuth = useSelector(state => !!state.auth.token);

    const onEditSubmit = (event, submitHandler) => {
        event.preventDefault();
        submitHandler(selectedTagId, inputValue);
        handleClose();
    }

    const onDeleteTag = deleteHandler => {
        deleteHandler(selectedTagId)
        handleClose();
    }

    let moreInfo = null;
    let modal = null;

    switch (props.moreInfo.type) {
        case 'tagList':
            moreInfo = props.moreInfo.data.map(tag => (
                <Chip key={tag.id}
                    label={t(tag.title)}
                    variant={!tag.clicked ? 'outlined' : null}
                    onClick={tag.handleClick}
                    style={{ margin: '0 0.7rem', fontFamily: 'Comfortaa-regular' }}
                    onDelete={isAuth ? () => handleOpen(tag) : null}
                    deleteIcon={isAuth ? <EditIcon /> : null}
                />
            ));

            modal = (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}
                        component="form"
                        noValidate
                        autoComplete="off">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('res_Edit')}
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label={t('res_PostTag')}
                            sx={{ marginBottom: '20px', width: '100%' }}
                            value={inputValue}
                            onChange={event => setInputValue(event.target.value)}
                        />
                        <div className={classes.modalButtonsWrapper}>
                            <Tooltip title={t('res_DeleteTagTooltip')}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onDeleteTag(props.moreInfo.onTagDelete)}
                                    sx={{ textTransform: 'none', width: '8rem' }}>
                                    {t('res_Delete')}
                                </Button>
                            </Tooltip>
                            <Button
                                variant="contained"
                                type="submit"
                                color="accent"
                                onClick={(event) => onEditSubmit(event, props.moreInfo.onTagUpdate)}
                                sx={{ textTransform: 'none', width: '8rem' }}>
                                {t('res_Save')}
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )
            break;
        default:
            moreInfo = null;
            break;
    }

    let adminButton = null;

    if (props.adminButton) {
        adminButton = (
            <Button
                variant="contained"
                color="accent"
                onClick={props.adminButton.clicked}
                sx={{ textTransform: 'none', marginLeft: '30px' }}>
                {t(props.adminButton.text)}
            </Button>
        )
    }

    return (
        <div className={classes.headerWrapper}>
            <Typography>{t(props.pageTitle)}</Typography>
            {moreInfo}
            {adminButton}
            {modal}
        </div>
    )
}

export default PageHeader;