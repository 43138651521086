import React from 'react';
import Typography from '@mui/material/Typography';
import classes from './Footer.module.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as TiktokIcon } from '../../assets/icons/tiktok-icon.svg';

import { useTranslation } from 'react-i18next';

const facebookPageUrl = 'https://www.facebook.com/mydietitianmama';
const instagramPageUrl = 'https://www.instagram.com/mydietitianmama/';
const tiktokPageUrl = 'https://www.tiktok.com/@mydietitianmama';

const Footer = props => {

    const { t } = useTranslation();

    const openWebsite = site => window.open(site);

    return (
        <div className={classes.FooterWrapper}>
            <Typography sx={{ marginBottom: '2rem', textAlign: 'center' }} classes={classes.serviceItem} variant="body2" color="text.primary">
                Βίκυ Αθανασιάδου Διατροφολόγος Διαιτολόγος
            </Typography>
            <div className={classes.firstRow}>
                <div className={classes.flex}>
                    <PhoneIcon sx={{ marginRight: 1.5 }} />
                    <a href="tel:+306930311241" className={classes.text}>+30 6930311241</a>
                </div>
                <div className={classes.flex}>
                    <EmailIcon sx={{ marginRight: 1.5, marginLeft: 2.5 }} />
                    <a className={classes.text} href="mailto:vickyath11@gmail.com" target="_blank" rel="noopener noreferrer">vickyath11@gmail.com</a>
                </div>
            </div>
            <div className={classes.secondRow}>
                <FacebookIcon className={classes.icon} onClick={() => openWebsite(facebookPageUrl)} />
                <InstagramIcon className={classes.icon} onClick={() => openWebsite(instagramPageUrl)} />
                <TiktokIcon className={classes.icon} onClick={() => openWebsite(tiktokPageUrl)} />
            </div>
        </div >
    )
}

export default Footer;