import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import classes from './AdminPage.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/auth';

const AdminPage = props => {
    const [values, setValues] = React.useState({
        userName: '',
        password: '',
        showPassword: false
    });

    const { loading, error, isAuthenticated, authRedirectPath } = useSelector(state => {
        return {
            loading: state.auth.loading,
            error: state.auth.error,
            isAuthenticated: !!state.auth.token,
            authRedirectPath: state.auth.authRedirectPath
        }
    });

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onAuth = (email, password, isSignup) => dispatch(actions.auth(email, password));

    useEffect(() => {
        if (authRedirectPath !== '/') {
            dispatch(actions.setAuthRedirectPath('/'));
        }
    }, [authRedirectPath, dispatch]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitHandler = event => {
        event.preventDefault();
        console.log('on submit')
        onAuth(values.userName, values.password, false);
        navigate("/");
    }

    return (
        <div className={classes.adminPageWrapper}>
            <Typography variant="body1" gutterBottom component="div">
                {t('res_AdminFormTItle')}
            </Typography>
            <div className={classes.inputs}>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{t('res_UserName')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-username"
                        value={values.userName}
                        onChange={handleChange('userName')}
                        label="User name"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{t('res_Password')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
            </div>
            <Button variant="contained" color="success" onClick={submitHandler} sx={{ textTransform: 'none' }}>
                {t('res_SignIn')}
            </Button>
        </div>
    )
}

export default AdminPage;