import React, { useCallback, useState } from 'react';
import { EditorState, ContentState, convertFromHTML, DefaultDraftBlockRenderMap, getSafeBodyFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.css'

const TextEditor = props => {
    const query = useQuery();
    const hasBody = !!query.get("postId");

    const postBody = useSelector(state => state.posts.selectedPostBody);
    const postBodyLoading = useSelector(state => state.posts.postBodyLoading);
    const storage = useSelector(state => state.auth.storage);

    let initalState;
    if (hasBody && postBody && !postBodyLoading) {
        const blocksFromHtml = htmlToDraft(postBody.replace(/(<\/?)figure((?:\s+.*?)?>)/g, ''));
        const { contentBlocks, entityMap } = blocksFromHtml;
        const content = ContentState.createFromBlockArray(contentBlocks, entityMap);
        initalState = EditorState.createWithContent(content);
    }
    else {
        initalState = EditorState.createEmpty();
    }

    const [editorState, setEditorState] = useState(initalState);

    const uploadImageCallback = useCallback((img) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${img.name}`);

            const uploadTask = uploadBytesResumable(storageRef, img);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                    console.log(error)
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        resolve({ data: { link: downloadURL } })
                    });
                }
            );
        })
    }, []);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML({
            styleToHTML: (style) => {
                const styles = {};
                if (style === 'BOLD') {
                    styles.fontWeight = 'bold';
                }
                if (style === 'ITALIC') {
                    styles.fontStyle = 'italic';
                }
                if (style === 'UNDERLINE') {
                    styles.textDecoration = 'underline';
                }
                if (style === 'STRIKETHROUGH') {
                    styles.textDecoration = 'line-through';
                }
                if (style.includes('fontsize')) {
                    styles.fontSize = style.split('-')[1] + 'px';
                }
                if (style.includes('color')) {
                    styles.color = style.split('-')[1];
                }
                if (style.includes('fontfamily')) {
                    styles.fontFamily = style.split('-')[1];
                }
                return <span style={styles}></span>;
            },
            entityToHTML: (entity, originalText) => {
                const styles = {};
                if (entity.type === 'LINK') {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                if (entity.type === 'IMAGE') {
                    styles.maxWidth = '70vw';
                    if (entity.data.alignment === 'left') {
                        styles.float = 'left';
                        styles.marginRight = '25px';
                    }
                    if (entity.data.alignment === 'right') {
                        styles.float = 'right';
                        styles.marginLeft = '25px';
                    }
                    if (entity.data.alignment === 'none') {
                        styles.margin = 'auto';
                        styles.display = 'block';
                    }
                    return <img src={entity.data.src} alt={entity.data.alt}
                        style={{ ...styles, width: entity.data.width, height: entity.data.height }} />
                }
                return originalText;
            }
        })(editorState.getCurrentContent());
        props.onChange(currentContentAsHTML);
    }
    return (
        <Editor defaultEditorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{
                image: {
                    uploadEnabled: true,
                    uploadCallback: uploadImageCallback,
                    alt: { present: false, mandatory: false },
                    previewImage: true
                }
            }}
            stripPastedStyles={true}
        />
    )
}

export default TextEditor;