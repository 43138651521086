import React, { Fragment, useEffect, useState } from 'react';
import classes from './Home.module.css';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import bioImage from '../../assets/images/bio-gr1.png';
import bioVertical from '../../assets/images/bio-gr-vertical.png';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as navActions from '../../store/actions/navigation';

const Home = props => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const loading = useSelector(state => state.auth.loading);
    const isAuth = useSelector(state => !!state.auth.token);
    const [imageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        const image = new Image();
        image.src = require('../../assets/images/home-foto.png');
        image.onload = () => setImageLoading(false);
    }, []);

    let bio = bioImage;

    if (width <= 710) {
        bio = bioVertical;
    }

    const getTextWidth = () => {
        if (width <= 500) {
            return '90%';
        } else if (width <= 700) {
            return '80%';
        } else if (width <= 1000) {
            return '62%';
        } else {
            return '58%';
        }
    }

    let content = <CircularProgress />;

    if (!loading && !imageLoading) {
        content = (
            <Fragment>
                <div className={classes.homeImage}>
                    <Typography sx={{ textAlign: 'center', width: getTextWidth() }} gutterBottom variant="h4" component="div">
                        {t('res_HomePhotoText')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="accent"
                        onClick={() => {
                            navigate('/services');
                            dispatch(navActions.changeTab('res_ServicesTitle'));
                        }}
                        sx={{ textTransform: 'upercase', marginBottom: 1 }}>
                        <Typography gutterBottom variant="h3" component="div" sx={{ textTransform: 'none' }}>
                            {t('res_SeeMyServices')}
                        </Typography>
                    </Button>
                </div>
                <div className={classes.bio}>
                    <img className={classes.bioImage} src={bio} alt="bio" />
                </div>
                <Divider sx={{ width: '100vw' }} />
                <div className={classes.servicesSection}>
                    <Typography sx={{ marginBottom: '2rem' }} gutterBottom variant="h5" component="div">
                        {t('res_Services')}
                    </Typography>
                    <Typography sx={{ marginBottom: '2rem' }} className={classes.serviceItem} variant="body1" color="text.secondary">
                        {t('res_ServicesText')}
                    </Typography>
                    <div className={classes.buttonsWrapper}>
                        {isAuth ? (<Button
                            variant="contained"
                            color="accent"
                            onClick={() => {
                                navigate('/appointments');
                                dispatch(navActions.changeTab('res_Appointments'));
                            }}
                            sx={{ textTransform: 'none', marginBottom: '2rem', marginX: '2rem' }}>
                            {t('res_Appointments')}
                        </Button>) : null}
                        <Button
                            variant="contained"
                            color="accent"
                            onClick={() => {
                                navigate('/services');
                                dispatch(navActions.changeTab('res_ServicesTitle'));
                            }}
                            sx={{ textTransform: 'none', marginBottom: '2rem', marginX: '2rem' }}>
                            {t('res_SeeMore')}
                        </Button>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <div className={classes.homeWrapper}>
            {content}
        </div>
    )
}

export default Home;