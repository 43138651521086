import React, { useEffect, useCallback, useState } from 'react';
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';

import Appointments from './Containers/Appointments/Appointments';
import Blog from './Containers/Blog/Blog';
import Eshop from './Containers/Eshop/Eshop';
import Home from './Containers/Home/Home';
import Webinars from './Containers/Webinars/Webinars';
import Layout from './hoc/Layout/Layout';
import Post from './Components/Post/Post';
import NewPostPage from './Containers/NewPostPage/NewPostPage';
import AdminPage from './Containers/AdminPage/AdminPage';
import Services from './Containers/Services/Services';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as authActions from './store/actions/auth';
import * as navActions from './store/actions/navigation';

function App() {

  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const onTryAutoSignup = useCallback(() => dispatch(authActions.authCheckState()), [dispatch]);
  const onSetChosenTab = useCallback(() => dispatch(navActions.findChosenTab(location.pathname)), [dispatch, location.pathname]);

  useEffect(() => {
    Promise.all([
      document.fonts.load('12px Sunday'),
      document.fonts.load('12px Comfortaa-bold'),
      document.fonts.load('12px Comfortaa-regular'),
    ]).then(() => setIsReady(true));
  }, [])

  useEffect(() => onTryAutoSignup(), [onTryAutoSignup]);
  useEffect(() => onSetChosenTab(), [onSetChosenTab]);
  useEffect(() => { window.scrollTo(0, 0); }, [location]);

  let routes = (
    <Routes >
      <Route path="/appointments" element={<Appointments />} />
      <Route path="/posts" element={<Blog />} />
      <Route path="/posts/:id" element={<Post />} />
      <Route path="/e-shop" element={<Eshop />} />
      <Route path="/webinars" element={<Webinars />} />
      <Route path="/upload" element={<NewPostPage />} />
      <Route path="/admin-mama" element={<AdminPage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/" element={<Home />} />
      <Route
        path="*"
        element={<Navigate to="/" />}
      />
    </Routes >
  );

  let appContent = (
    <div className="fullPage">
      <CircularProgress />
    </div>
  )

  if (isReady) {
    appContent = (
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    )
  }
  return appContent;
}

export default App;
