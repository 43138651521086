import React from 'react';

const Webinars = props => {
    return (
        <div>
            Webinars works!
        </div>
    )
}

export default Webinars;