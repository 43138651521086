import React, { Fragment, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classes from './Card.module.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import * as actions from '../../store/actions/posts';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
};


const MediaCard = props => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuth = useSelector(state => !!state.auth.token);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleOnEdit = () => {
        dispatch(actions.setPostBodyLoading(true))
        navigate(`/upload?postId=${props.article.id}`);
    }

    const getImageHeight = () => {
        if (width <= 500) {
            return '172';
        } else if (width <= 700) {
            return '200';
        } else if (width <= 1000) {
            return '300';
        } else {
            return '172';
        }
    }

    let isDraftCaption = null;

    if (props.article.isDraft) {
        isDraftCaption = (
            <Typography id="modal-modal-title" variant="body2" component="h2" color="red">
                {t('res_DraftPost')}
            </Typography>
        )
    }

    let adminButtons = null;
    if (isAuth) {
        adminButtons = (
            <Fragment>
                <Tooltip title={t('res_Edit')}>
                    <IconButton onClick={() => handleOnEdit(props.article.id)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('res_Delete')}>
                    <IconButton onClick={handleOpen}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}
                        component="form"
                        noValidate
                        autoComplete="off">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t('res_DeleteArticleConfirm')}
                        </Typography>
                        <div className={classes.modalButtonsWrapper}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => dispatch(actions.deletePost(props.article.id))}
                                sx={{ textTransform: 'none', width: '8rem' }}>
                                {t('res_Delete')}
                            </Button>
                            <Button
                                variant="contained"
                                color="accent"
                                onClick={handleClose}
                                sx={{ textTransform: 'none', width: '8rem' }}>
                                {t('res_Cancel')}
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </Fragment>
        )
    }

    return (
        <Card sx={{ margin: 4 }} className={classes.cardWrapper} >
            <CardMedia
                component="img"
                height={getImageHeight()}
                image={props.article.image}
                alt={props.article.title}
            />
            <CardContent>
                {isDraftCaption}
                <Typography gutterBottom variant="h5" component="div">
                    {props.article.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ maxHeight: '2rem' }}>
                    {props.article.abstruct}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" sx={{ textTransform: 'none', color: 'text.primary' }} onClick={() => { navigate(`${props.article.id}`) }}>{t('res_SeeMore')}</Button>
                {adminButtons}
            </CardActions>
        </Card >
    );
}

export default MediaCard;