import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import classes from './ServiceItem.module.css';

const ServiceItem = props => {
    const { t } = useTranslation();

    let subtitle = null;

    if (props.subtitle) {
        subtitle = (
            <Typography sx={{ marginBottom: '2rem' }} gutterBottom variant="body1" component="div">
                {t(props.subtitle)}
            </Typography>
        )
    }

    let body = null;

    if (props.body) {
        body = (
            <Typography gutterBottom variant="body2" component="div">
                {t(props.body)}
            </Typography>
        )
    }

    let list = null;

    if (props.list) {
        list = (
            <ul>
                {props.list.map((item, index) => (
                    <li key={index}>
                        <Typography gutterBottom variant="body2" component="div" key={item}>
                            {t(item)}
                        </Typography>
                    </li>
                ))}
            </ul>
        )
    }

    let footer = null;

    if (props.footer) {
        footer = (
            <Typography gutterBottom variant="body2" component="div">
                {t(props.footer)}
            </Typography>
        )
    }

    return (
        <div className={classes.serviceItemWrapper}>
            <img src={props.image} alt={props.title} className={classes.serviceImage} />
            <div className={classes.textContainer}>
                <Typography sx={{ marginBottom: '0.5rem', textTransform: 'uppercase' }} gutterBottom variant="h5" component="div">
                    {t(props.title)}
                </Typography>
                {subtitle}
                {body}
                {list}
                {footer}
            </div>
        </div>
    )
}

export default ServiceItem;