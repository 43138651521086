export const tabs = [
    {
        title: 'res_ServicesTitle',
        link: '/services'
    },
    {
        title: 'res_Posts',
        link: '/posts'
    },
    {
        title: 'res_Appointments',
        link: '/appointments',
        isAlpha: true
    },
    {
        title: 'res_Webinars',
        link: '/webinars',
        isAlpha: true
    },
    {
        title: 'res_Eshop',
        link: '/e-shop',
        isAlpha: true
    }
]