import actionTypes from '../actions/actionTypes';
import { updateObject } from '../../common/utils';

const initialState = {
    chosenTab: null
};

const changeTab = (state, action) => {
    return updateObject(state, {
        chosenTab: action.tab
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_TAB: return changeTab(state, action);
        default: return state;
    }
};

export default reducer;