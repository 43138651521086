import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import classes from './Post.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import * as actions from '../../store/actions/posts';

const Post = props => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const index = location.pathname.lastIndexOf('/') + 1;
        const postId = location.pathname.substring(index);
        dispatch(actions.getPostByIdAsync(postId));
    }, [])

    const selectedPost = useSelector(state => state.posts.selectedPost);
    const isLoading = useSelector(state => state.posts.postBodyLoading);

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    let output = <CircularProgress />;

    if (!isLoading) {
        output = (
            <div className={classes.postWrapper}>
                <Typography gutterBottom variant="h4" component="div">
                    {selectedPost.title}
                </Typography>
                <img alt={selectedPost.title} src={selectedPost.fullImage} className={classes.postImage} />
                <div className={classes.preview} dangerouslySetInnerHTML={createMarkup(selectedPost.body)}></div>
            </div>
        );
    }

    return output

}

export default Post;