import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './assets/fonts/comfortaa-v37-latin_greek-regular.woff2';
import './assets/fonts/comfortaa-v37-latin_greek-regular.woff';
import './assets/fonts/comfortaa-v37-latin_greek-700.woff2';
import './assets/fonts/comfortaa-v37-latin_greek-700.woff';
import './assets/fonts/Sunday.ttf.woff';

//Redux
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth';
import navigationReducer from './store/reducers/navigation';
import postsReducer from './store/reducers/posts';

const rootReducer = combineReducers({
  auth: authReducer,
  nav: navigationReducer,
  posts: postsReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const theme = createTheme({
  palette: {
    primary: {
      main: '#E6E7E2',
    },
    secondary: {
      main: '#849f8a',
      light: '#b4d0ba',
      dark: '#57715d'
    },
    accent: {
      main: '#a4b792'
    }
  },
  typography: {
    h2: {
      fontFamily: 'Sunday'
    },
    h3: {
      fontFamily: 'Sunday',
      '@media (max-width:900px)': {
        fontSize: '1.2rem',
      },
      '@media (max-width:700px)': {
        fontSize: '1rem',
      }
    },
    h4: {
      fontFamily: 'Comfortaa-bold',
      '@media (max-width:1200px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width:900px)': {
        fontSize: '1.2rem',
      },
      '@media (max-width:650px)': {
        fontSize: '1rem',
      }
    },
    h5: {
    },
    body1: {
      fontFamily: 'Comfortaa-regular'
    },
    body2: {
      fontFamily: 'Comfortaa-regular'
    },
    button: {
      fontFamily: 'Comfortaa-bold'
    }
  }
});


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <I18nextProvider>
          <ThemeProvider theme={theme}>
            <App i18n={i18n} />
          </ThemeProvider>
        </I18nextProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
