import React, { useEffect, useState, useCallback, Fragment, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextEditor from '../../Components/TextEditor/TextEditor';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import AvatarEditor from "react-avatar-editor";
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';

import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';
import * as actions from '../../store/actions/posts';
import classes from './NewPostPage.module.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const NewPostPage = props => {
    const query = useQuery();
    const hasSelectedPost = !!query.get("postId");
    const dispatch = useDispatch();

    useEffect(() => {
        let postId = query.get("postId");
        if (postId) {
            dispatch(actions.getPostByIdAsync(postId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const postTags = useSelector(state => state.posts.postTags);
    const selectedPost = useSelector(state => state.posts.selectedPost);
    const isLoading = useSelector(state => state.posts.postBodyLoading);
    const storage = useSelector(state => state.auth.storage);

    const [formValues, setFormValues] = useState({
        title: { value: selectedPost?.title || '', touched: false, required: true, hasError: false },
        abstruct: { value: selectedPost?.abstruct || '', touched: false, required: true, hasError: false },
        image: { value: selectedPost?.image || '', touched: false, required: false, hasError: false },
        fullImage: { value: selectedPost?.fullImage || '', touched: false, required: false },
        tags: { value: hasSelectedPost ? selectedPost.tags : [], touched: false, required: false },
        body: { value: '', touched: false, required: false },
    });
    const [selectedTags, setSelectedTags] = useState(hasSelectedPost ? selectedPost.tags : [])
    const [imageName, setImageName] = useState('');
    const [upImg, setUpImg] = useState();
    const [fullImage, setFullImage] = useState();
    const [open, setOpen] = useState(false);
    const [cropScale, setCropScale] = useState(1);
    const [previewUrl, setPreviewUrl] = useState();

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    });

    const EditorRef = useRef(null);

    const handleImageUpload = async event => {
        if (event.target.files && event.target.files.length > 0) {
            setImageName(event.target.files[0].name);
            setUpImg(event.target.files[0]);
            setOpen(true);
            const fullImg = await storeImage(event.target.files[0]);
            setFullImage(fullImg);
            changeFormValue('image', 'hasError', false);
        }
    };

    const inputChangeHandler = useCallback((type, newValue) => {
        const newFormValues = { ...formValues };
        if (type === 'tags') {
            newFormValues[type].value = [...newValue];
        } else {
            newFormValues[type].value = newValue;
        }
        newFormValues[type].touched = true;

        if (newFormValues[type].required && newFormValues[type].hasError && !!newValue) {
            newFormValues[type].hasError = false;
        }
        setFormValues(newFormValues);
    }, [formValues]);

    const changeFormValue = (formElement, type, newValue) => {
        const newFormValues = { ...formValues };
        newFormValues[formElement][type] = newValue;
        setFormValues(newFormValues);
    };

    const storeImage = img => {
        return new Promise(resolve => {
            const storageRef = ref(storage, `images/${img.name}`);

            const uploadTask = uploadBytesResumable(storageRef, img);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                    console.log(error);
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        resolve(downloadURL);
                    });
                }
            );
        });
    }

    const submitHandler = useCallback(async (event, isDraft) => {
        event.preventDefault();
        let formIsValid = true;
        if (hasSelectedPost) {
            let postId = query.get("postId");
            const olfFormValues = { ...formValues };
            const newFormValues = {};
            for (let key in olfFormValues) {
                if (!formValues[key].value && !olfFormValues[key].touched) {
                    newFormValues[key] = selectedPost[key];
                } else {
                    newFormValues[key] = olfFormValues[key].value;
                }

                if (olfFormValues[key].required && !newFormValues[key]) {
                    changeFormValue(key, 'hasError', true);
                }

                formIsValid = formIsValid && (olfFormValues[key].required ? !!newFormValues[key] : true);
            }

            newFormValues.isDraft = isDraft;

            if (!formIsValid) {
                return;
            }

            if (upImg) {
                const imgUrl = await storeImage(upImg);
                newFormValues.image = imgUrl;
                newFormValues.fullImage = fullImage;
            }

            dispatch(actions.editPost(postId, newFormValues));
            navigate('/posts');
        } else {
            const olfFormValues = { ...formValues };
            const newFormValues = {};
            for (let key in olfFormValues) {
                newFormValues[key] = olfFormValues[key].value;

                if (olfFormValues[key].required && !newFormValues[key]) {
                    changeFormValue(key, 'hasError', true);
                }

                formIsValid = formIsValid && (olfFormValues[key].required ? !!olfFormValues[key].value : true);
            }

            if (!upImg) {
                changeFormValue('image', 'hasError', true);
                formIsValid = false;
                return;
            }

            const imgUrl = await storeImage(upImg);
            newFormValues.image = imgUrl;
            newFormValues.fullImage = fullImage;
            newFormValues.isDraft = isDraft;

            formIsValid = formIsValid && !!imgUrl && !!fullImage;

            if (!formIsValid) {
                return;
            }

            dispatch(actions.addPost(newFormValues));
            navigate('/posts');
        }
    }, [upImg, fullImage, selectedPost])

    const updateTags = useCallback((newTags) => {
        const newFormValues = { ...formValues };
        const oldTags = formValues.tags.value || [];
        let newTagsArray;
        if (newTags.length < oldTags.length) {
            newTagsArray = newTags;
        } else {
            newTagsArray = [...new Set([...oldTags, ...newTags])];
        }
        newFormValues.tags.value = newTagsArray;
        newFormValues.tags.touched = true;
        setFormValues(newFormValues);
        setSelectedTags(newTagsArray);
    }, [formValues]);

    const addNewTag = useCallback(event => {
        if (!event || !event.target || !event.target.value) {
            return;
        }
        //Do not update db if exists
        if ((postTags && postTags.some(tag => tag.title === event.target.value))) {
            updateTags([...formValues.tags.value, event.target.value]);
            return;
        }
        dispatch(actions.addPostTag(event.target.value));
        updateTags([...formValues.tags.value, event.target.value]);
    }, [dispatch, postTags, updateTags, formValues]);

    const handleClose = () => setOpen(false);

    const handleCropScaleChange = event => {
        setCropScale(event.target.value / 100);
    }

    const handleCropImage = () => {
        const canvasScaled = EditorRef.current.getImageScaledToCanvas();
        canvasScaled.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = 'small_' + imageName;
            console.log(window.URL.createObjectURL(blob))
            setUpImg(blob);
            handleClose();
            setPreviewUrl(window.URL.createObjectURL(blob));
        }, 'image/jpeg');
    }

    let content = <CircularProgress />;
    let imagePreview = null;

    if (selectedPost?.image && !isLoading) {
        imagePreview = (
            <div className={classes.previewImageWrapper}>
                <img alt="preview" src={selectedPost.image} className={classes.imagePreview} />
            </div>
        );
    }

    if (previewUrl) {
        imagePreview = (
            <div className={classes.previewImageWrapper}>
                <img alt="preview" src={previewUrl} className={classes.imagePreview} />
            </div>
        );
    }

    let title = 'res_NewPost';
    let txtColor = 'default';
    let draftBtnTilte = 'res_SaveDraft';

    if (hasSelectedPost && selectedPost.isDraft) {
        title = 'res_DraftPost';
        txtColor = 'red';
    }

    if (hasSelectedPost && !selectedPost.isDraft) {
        draftBtnTilte = 'res_ConvertToDraft';
    }

    if (!isLoading) {
        content = (
            <Fragment>
                <Typography sx={{ marginBottom: '0.5rem', color: txtColor }} gutterBottom variant="h5" component="div">
                    {t(title)}
                </Typography>
                <Box
                    component="form"
                    sx={{ m: 1, width: '60vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    noValidate
                    autoComplete="off"
                >
                    <div className={classes.thirdRow}>
                        <div style={previewUrl ? { width: '50%' } : { width: '100%' }}>
                            <TextField
                                required
                                error={formValues.title.hasError}
                                id="outlined-required"
                                label={t('res_Title')}
                                sx={{ marginBottom: '20px', width: '100%' }}
                                defaultValue={selectedPost?.title || null}
                                onChange={event => inputChangeHandler('title', event.target.value)}
                            />
                            <TextField
                                required
                                error={formValues.abstruct.hasError}
                                id="outlined-required"
                                label={t('res_Abstruct')}
                                multiline
                                rows={5}
                                sx={{ marginBottom: '20px', width: '100%' }}
                                defaultValue={selectedPost?.abstruct || null}
                                onChange={event => inputChangeHandler('abstruct', event.target.value)}
                            />
                        </div>
                        {imagePreview}
                    </div>
                    <div className={classes.thirdRow}>
                        <TextField
                            error={formValues.image.hasError}
                            required
                            type="file"
                            label={t('res_Image')}
                            onChange={event => handleImageUpload(event)}
                            focused
                            sx={{ width: '48%' }}
                        />
                        <Autocomplete
                            id="tag-selector"
                            multiple
                            options={postTags.map(tag => tag.title)}
                            renderInput={(params) => <TextField {...params} label={t('res_PostTag')} />}
                            defaultValue={selectedPost?.tags || []}
                            value={selectedTags}
                            onBlur={addNewTag}
                            onChange={(event, value) => updateTags(value)}
                            sx={{ width: '48%' }}
                        />
                    </div>
                    <div className={classes.editorWrapper}>
                        <TextEditor onChange={(state) => inputChangeHandler('body', state)} />
                    </div>
                    <div className={classes.flexRow}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="accent"
                            onClick={(event) => submitHandler(event, true)}
                            sx={{ textTransform: 'none', width: '8.5rem', margin: '1rem' }}>
                            {t(draftBtnTilte)}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="accent"
                            onClick={(event) => submitHandler(event, false)}
                            sx={{ textTransform: 'none', width: '8.5rem', margin: '1rem' }}>
                            {t('res_Post')}
                        </Button>
                    </div>
                </Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <AvatarEditor
                            ref={EditorRef}
                            image={upImg}
                            width={948}
                            height={412.8}
                            border={[50, 50]}
                            scale={cropScale}
                            color={[152, 146, 146, 0.41]}
                        />
                        <Slider defaultValue={100} max={300} color="primary" aria-label="slider" valueLabelDisplay="auto" onChange={handleCropScaleChange} />
                        <Button
                            variant="contained"
                            color="accent"
                            onClick={handleCropImage}
                            sx={{ textTransform: 'none', width: '8rem' }}>
                            OK
                        </Button>
                    </Box>
                </Modal>
            </Fragment >
        );
    }

    return (
        <div className={classes.NewPostPageWrapper}>
            {content}
        </div>
    )
}

export default NewPostPage;