import React, { Fragment, useEffect, useCallback, useState } from 'react';
import Card from '../../Components/Card/Card';
import PageHeader from '../../Components/PageHeader/PageHeader';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { areImagesLoaded } from '../../common/utils';

import classes from './Blog.module.css';
import * as actions from '../../store/actions/posts';

const Blog = props => {

    const isAuth = useSelector(state => !!state.auth.token);
    const postTags = useSelector(state => state.posts.postTags);
    const selectedTag = useSelector(state => state.posts.selectedTag);
    const postsLoading = useSelector(state => state.posts.postsLoading);
    const [draftsSelected, setDraftsSelected] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getPosts(isAuth));
        dispatch(actions.getPostTags());
        dispatch(actions.setSelectedPost({}));
        dispatch(actions.setPostBody({}));
    }, []);

    const updateTag = useCallback((tagId, value) => {
        dispatch(actions.editPostTag(tagId, value))
    }, [dispatch]);

    const deleteTag = useCallback(tagId => {
        dispatch(actions.deletePostTag(tagId))
    }, [dispatch]);

    const articles = useSelector(state => state.posts.postList);

    // useEffect(() => {
    //     const checkIfAreLoadedAsync = async (images) => {
    //         const areLoaded = await areImagesLoaded(images);
    //         setImagesLoaded(areLoaded)
    //     }

    //     if (articles.length > 0) {
    //         const images = articles.map(article => article.image);
    //         checkIfAreLoadedAsync(images);
    //     } else {
    //         setImagesLoaded(true);
    //     }

    // }, [articles]);

    const moreInfo = {
        type: 'tagList',
        data: postTags.map(tag => {
            return { id: tag.id, title: tag.title, clicked: selectedTag.id === tag.id, handleClick: () => changeTag(tag) }
        }),
        onTagUpdate: updateTag,
        onTagDelete: deleteTag
    };

    const changeTag = useCallback((tag) => {
        if (selectedTag.id === tag.id) { //We un-chose the tag
            dispatch(actions.setSelectedTag());
            dispatch(actions.getPosts(isAuth));
            return;
        }
        dispatch(actions.setSelectedTag(tag.id));
        dispatch(actions.getPostsByTag(tag.title));
    }, [dispatch, selectedTag]);

    const getDraftPosts = useCallback(() => {
        const oldDraftsSelected = draftsSelected;
        setDraftsSelected(!oldDraftsSelected);
        if (oldDraftsSelected) { //We un-chose the tag
            dispatch(actions.getPosts(isAuth));
            return;
        }
        dispatch(actions.getDrafts());
    }, [dispatch, selectedTag, draftsSelected, setDraftsSelected]);

    let addPostButton = null;

    if (isAuth) {
        addPostButton = {
            clicked: () => { navigate('/upload'); },
            text: 'res_NewPost'
        }

        moreInfo.data.push({ id: 'drafts', title: 'res_DraftPosts', clicked: draftsSelected, handleClick: () => getDraftPosts() })
    }

    let content = <CircularProgress />

    if (!postsLoading) {
        content = (
            <Fragment>
                <PageHeader pageTitle="res_Posts" moreInfo={moreInfo} adminButton={addPostButton} />
                <div className={classes['blog-wrapper']}>
                    {articles.map(article => (
                        <Card key={article.id} style={{ flex: 1 }} article={article} />
                    ))}
                </div>
            </Fragment>
        );
    }

    return content;
}

export default Blog;