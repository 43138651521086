import React, { useState, useEffect } from 'react';
import ServiceItem from './ServiceItem/ServiceItem';
import image1 from '../../assets/images/service-1.png';
import image2 from '../../assets/images/service-2.png';
import image3 from '../../assets/images/service-3.png';
import image4 from '../../assets/images/service-4.png';
import CircularProgress from '@mui/material/CircularProgress';

import { areImagesLoaded } from '../../common/utils';
import classes from './Services.module.css';

const serviceItems = [
    {
        image: image1,
        title: 'res_SessionTitle',
        subtitle: 'res_ChildSessionSubitle',
        body: 'res_ChildSessionBody'
    },
    {
        image: image2,
        title: 'res_SessionTitle',
        subtitle: 'res_PregnantSessionSubitle',
        body: 'res_PregnantSessionBody'
    },
    {
        image: image3,
        title: 'res_Webinars',
        body: 'res_WebinarsBody',
        list: ['res_WebinarSolidFood', 'res_WebinarFirstYearDiet', 'res_WebinarPregnancyDiet'],
        footer: 'res_WebinarsFooter'
    },
    {
        image: image4,
        title: 'res_Ebooks',
        list: ['res_WebinarSolidFood', 'res_WebinarFirstYearDiet', 'res_WebinarPregnancyDiet']
    }
];


const Services = props => {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const checkIfAreLoadedAsync = async () => {
            const areLoaded = await areImagesLoaded(['service-1.png', 'service-2.png', 'service-3.png', 'service-4.png']);
            setLoaded(areLoaded)
        }
        checkIfAreLoadedAsync();
    }, []);

    let content = <CircularProgress />;

    if (loaded) {
        content = serviceItems.map((serviceItem, index) => {
            return (
                <ServiceItem
                    key={index}
                    image={serviceItem.image}
                    title={serviceItem.title}
                    subtitle={serviceItem.subtitle}
                    body={serviceItem.body}
                    list={serviceItem.list}
                    footer={serviceItem.footer}
                />
            )
        });
    }

    return (
        <div className={classes.servicesWrapper}>
            {content}
        </div>
    )
}

export default Services;