import actionTypes from './actionTypes';
import { tabs } from '../../common/constants';

export const changeTab = tab => {
    return {
        type: actionTypes.CHANGE_TAB,
        tab: tab
    };
};

export const findChosenTab = path => {
    return dispatch => {
        if (path === '/') {
            dispatch(changeTab());
            return;
        }
        dispatch(changeTab(tabs.find(page => path.includes(page.link))?.title || null));
    }
}