import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://my-dietitian-mama-default-rtdb.europe-west1.firebasedatabase.app/'
});
instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// instance.defaults.headers.common['Accept'] = 'application/x-www-form-urlencoded';
// instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


export default instance;